import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { getImage } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Layout from "../components/layout";
import {
  section,
  pageHeader,
  sectionText,
  title,
  teamSection,
  teamPhoto,
  storySection,
  teamHeader,
  teamItem,
  card,
  row,
  personName,
  role,
  container,
  paragraph,
  blueShape,
  beigeShape,
  whiteShapeRight,
  whiteShapeLeft,
} from "./about.module.css";

const AboutPage = ({ data }) => {
  return (
    <Layout pageTitle="About us">
      <Container fluid className={container}>
        <div className={blueShape} />
        <div className={beigeShape} />
        <div className={whiteShapeRight} />
        <div className={whiteShapeLeft} />

        <Container fluid className={section}>
          <div className={sectionText}>
            <h2 className={pageHeader}>
              Experience the Simplicity: How Floatist Redefines Yacht Operations
            </h2>
            <p className={paragraph}>
              Floatist is not just a yacht, fleet and charter management tool;
              it's a game-changer that unites all your essential information,
              people, and tools under one roof, revolutionizing the way you run
              your business.
            </p>
          </div>
        </Container>
        <Container fluid className={storySection}>
          <div className={sectionText}>
            <h2 className={title}>Our story</h2>
            <p className={paragraph}>
              At Floatist, we understand the intricate challenges faced by
              yachting professionals in managing their operations because we've
              experienced them ourselves first hand. As sailors by heart, and
              software engineers by trade, we combined our unique skillset to
              provide you with a comprehensive fleet and charter operations
              management platform that serves as your right hand, every step of
              the way.
            </p>
          </div>
        </Container>
        <Container fluid className={teamSection}>
          <Row className={row}>
            <Col lg={12} className={teamHeader}>
              <h1>Our team</h1>
            </Col>
            {data.allMdx.nodes.map((node) => (
              <Col md={12} lg={4} className={teamItem}>
                <Card className={card} key={node.id}>
                  <GatsbyImage
                    image={getImage(
                      node.frontmatter.profile_pic.childImageSharp
                    )}
                    alt="profile"
                    style={{
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                    }}
                  />
                  <Card.Body>
                    <Card.Title>
                      <h3 className={personName}>{node.frontmatter.name}</h3>
                    </Card.Title>
                    <Card.Text>
                      <p className={role}>
                        <span style={{ color: "grey" }}>
                          {node.frontmatter.role}
                        </span>
                      </p>
                      <MDXRenderer>{node.body}</MDXRenderer>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/content/about/" } }
      sort: { fields: frontmatter___order }
    ) {
      nodes {
        frontmatter {
          name
          role
          profile_pic {
            childImageSharp {
              gatsbyImageData(height: 400)
            }
          }
        }
        id
        body
      }
    }
  }
`;

export default AboutPage;
