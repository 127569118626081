// extracted by mini-css-extract-plugin
export var beigeShape = "about-module--beige-shape--1a0ea";
export var blueShape = "about-module--blue-shape--8bb90";
export var card = "about-module--card--14928";
export var container = "about-module--container--41d1b";
export var pageHeader = "about-module--page-header--fb4e4";
export var paragraph = "about-module--paragraph--52782";
export var personName = "about-module--person-name--8be4f";
export var role = "about-module--role--a35c4";
export var row = "about-module--row--1336f";
export var section = "about-module--section--9a81e";
export var sectionText = "about-module--section-text--1a7eb";
export var storySection = "about-module--story-section--dc7f0";
export var teamHeader = "about-module--team-header--409fd";
export var teamItem = "about-module--team-item--6d521";
export var teamPhoto = "about-module--team-photo--24af7";
export var teamSection = "about-module--team-section--1989b";
export var title = "about-module--title--7e010";
export var whiteShapeLeft = "about-module--white-shape-left--ddd47";
export var whiteShapeRight = "about-module--white-shape-right--2dde7";